<template>
  <div class="manageBox">
    <div class="top">
      <el-form ref="form" :inline="true" :model="queryParams" label-width="">
        <el-form-item label="流程名称">
          <el-input
            style="width: 230px"
            placeholder="请输入流程名称"
            clearable
            v-model="queryParams.keyWord"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList">查询</el-button>
        </el-form-item>
        <el-button style="float: right" type="primary" @click="toAdd"
          >新增</el-button
        >
      </el-form>
    </div>
    <div class="content">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        :row-style="{ height: '55px' }"
        style="width: 100%"
        :header-cell-style="headerCellStyle"
        class="content-itme"
      >
        <template slot="empty">
          <IsEmpty />
        </template>
        <el-table-column prop="name" align="center" label="流程名称" />
        <el-table-column
          prop="serviceObjectName"
          align="center"
          label="服务对象类别"
        />
        <el-table-column
          prop="enclosureName"
          align="center"
          label="审批附件类型"
        />
        <el-table-column prop="address" align="center" label="操作">
          <template slot-scope="scope">
            <span class="operation" @click="toView(scope.row)">查看</span>
            <span class="operation" @click="toEdit(scope.row)">编辑</span>
            <span class="operation stop" @click="deleteItem(scope.row)"
              >删除</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin: 20px 0"
        background
        layout="total, sizes, prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="pageNum"
        :page-sizes="[10, 20, 30, 40]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { getProcessList, deleteById } from '@/api/homeCasedCareServices'
export default {
  props: {
    activeType: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 10,
      loading: false,
      options: [],
      tableData: [
        {
          phone: 1
        }
      ],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyWord: ''
      }
    }
  },
  mounted() {
    console.log(this.activeType)
    this.getList()
  },
  methods: {
    /*删除*/
    deleteItem(row) {
      this.$confirm(`此操作将删除"${row.name}"流程, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteById({ id: row.id }).then((res) => {
            if (res.state === 0) {
              this.$message.success(res.data)
              this.getList()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    /*新增*/
    toAdd() {
      this.$router.push({
        path: '/homeCasedCareServices/subsidyAllocation/addSubsidyApprovalProcess'
      })
    },
    /*编辑*/
    toEdit(row) {
      this.$router.push({
        path: '/homeCasedCareServices/subsidyAllocation/editSubsidyApprovalProcess',
        query: { id: row.id }
      })
    },
    /*查看*/
    toView(row) {
      this.$router.push({
        path: '/homeCasedCareServices/subsidyAllocation/viewSubsidyApprovalProcess',
        query: { id: row.id }
      })
    },
    async getList() {
      this.loading = true
      const res = await getProcessList(this.queryParams)
      if (res.state === 0) {
        this.loading = false
        this.tableData = res.data.rows
        this.total = res.data.total
      }
    },
    // 翻页
    handleCurrentChange(val) {
      this.loading = true
      this.queryParams.pageNum = val
      this.getList()
    },
    handleSizeChange(val) {
      this.loading = true
      this.queryParams.pageSize = val
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.manageBox {
  // margin: 20px;
  background: #fff;
  min-height: calc(100vh - 130px);
  .eyes {
    // display: inline-block;
    width: 20px;
    height: 20px;
    float: right;
    margin-right: 20px;
    cursor: pointer;
  }
  .operation {
    margin-right: 20px;
    color: #3fcf89;
    cursor: pointer;
  }
  .stop {
    color: #f57473;
  }
  .widthStyle {
    width: 90%;
  }
}
.content {
  /*padding: 15px;*/
  padding-top: 20px;
}
.my_btn_duty {
  margin-right: 20px;
}
.el-button--text {
  outline: none;
  border: none;
}
.el-button--text:hover,
.el-button--text:focus {
  background: none !important;
  border: none !important;
  outline: none !important;
}
.el-button.is-disabled,
.el-button.is-disabled:hover,
.el-button.is-disabled:focus {
  color: #c0c4cc !important;
}
.w200px {
  width: 200px;
}
</style>
